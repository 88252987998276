import React from 'react';
import styled from 'styled-components';

import { Header } from 'components/header/Header';
import { HeaderLink } from 'components/header/HeaderLink';
import { WorkBlockTextRight } from 'components/work/WorkBlockTextRight';
import { responsiveFont } from 'styles/utils';
import { variables, breakpoints } from 'styles/variables';
import { Container } from 'components/container/Container';
import { Column } from 'components/grid/Column';
import { Row } from 'components/grid/Row';

import { Work } from 'components/work/Work';
import { WorkBlock } from 'components/work/WorkBlock';
import { WorkBlockImage } from 'components/work/WorkBlockImage';
import { WorkBlockTextLeft } from 'components/work/WorkBlockTextLeft';
import { SimpleFooter } from 'components/footer/SimpleFooter';

import WorkVideoBorstnikovoClip from '../../assets/video/work_Borstnikovo_2023_loop.mp4';
import WorkVideoBorstnikovo from '../../assets/video/work_Borstnikovo_2023.mp4';
import WorkVideoBevogPath from '../../assets/images/work_bevog.jpg';

import Img from 'gatsby-image';

import { graphql } from 'gatsby';

import Helmet from 'react-helmet';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import VideoPlayer from 'components/video/VideoPlayer';

import WorkFilter from 'components/work/WorkFilter';

// tslint:disable no-default-export

const Wrapper = styled.div`
  padding: 0px;
`;

const StrongText = styled.p`
  font-weight: 400;
  margin-bottom: 5px;
  font-size: 16px;
  margin-top: 15px;
  display: none;
`;

const AwardTitle = styled.p`
  margin-bottom: 5px;
  font-weight: 500;
`;

const AwardText = styled.p`
  margin-bottom: 5px;
  font-weight: 400;
  opacity: 0.5;
`;

const SectionTitle = styled.p`
  ${responsiveFont(38, 44)};

  font-family: ${variables.font.familyHeading};
  font-weight: 900;
  padding-bottom: 20px;
`;

export default ({ data, location }) => {
  const { t } = useTranslation();
  const playerRef = React.useRef<HTMLVideoElement>(null);
  const [fullscreen, setFullscreen] = React.useState(false);
  const [controls, setControls] = React.useState(true);

  const options = {
    preload: 'auto',
    controls: true,
    fullscreen: {
      options: {
        navigationUI: 'hide',
      },
    },
    userActions: {
      doubleClick: false,
    },
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    player.on('useractive', () => {
      setControls(true);
    });

    player.on('userinactive', () => {
      setControls(false);
    });

    player.on('ended', () => {
      setFullscreen(false);
      playerRef?.current?.currentTime(0);
    });

    player.on('fullscreenchange', () => {
      if (!player.isFullscreen()) {
        handleStop();
      }
    });
  };

  const handlePlay = (source) => {
    // @ts-ignore
    playerRef?.current?.src({ src: source, type: 'video/mp4' });
    setFullscreen(true);
    playerRef?.current?.play();
  };

  const handleStop = () => {
    setFullscreen(false);
    playerRef?.current?.pause();
  };

  return (
    <>
      <Header>
        <HeaderLink name={t('work')} href="/work" className="text-black" />
        <HeaderLink name={t('meet')} href="/contact" className="text-black" />
      </Header>
      <Helmet title="Work" />

      <Container>
        <Wrapper>
          <Row>
            <Column md={12} lg={12} sm={12}>
              <SectionTitle>{t('workTitle')}</SectionTitle>
              <WorkFilter />
            </Column>
          </Row>
        </Wrapper>
      </Container>

      <Work>
      <WorkBlock>
          <WorkBlockTextLeft
            title="Spacekart"
            year="2024"
            services="Brand development"
            extras=""
          />
          <WorkBlockImage titleMobile="Spacekart brand development" lg={8} md={8}>
            <Img
              loading="eager"
              fluid={data.workImageSpacekart.childImageSharp.fluid}
              alt="Spacekart brand development"
            />
          </WorkBlockImage>
        </WorkBlock>
      <WorkBlock>
          <WorkBlockTextLeft
            title="Borštnikovo"
            year="2024"
            services="Festival branding and campaign"
            extras=""
          />
          <WorkBlockImage titleMobile="Borštnikovo 2024 festival branding and campaign" lg={8} md={8}>
            <Img
              loading="eager"
              fluid={data.workImageBorstnikovo2024.childImageSharp.fluid}
              alt="Borštnikovo 2024 festival branding and campaign"
            />
          </WorkBlockImage>
        </WorkBlock>
      <WorkBlock>
          <WorkBlockTextLeft
            title="Borštnikovo"
            year="2023"
            services="Festival branding and campaign"
            extras=""
          />
          <WorkBlockImage external="video" lg={8} md={8}>
            <video
              preload="auto"
              muted
              playsInline
              loop
              autoPlay
              className="w-full cursor-pointer"
              src={WorkVideoBorstnikovoClip}
              onClick={() => handlePlay(WorkVideoBorstnikovo)}
            />
          </WorkBlockImage>
        </WorkBlock>
      <WorkBlock>
          <WorkBlockTextLeft
            title="Štajerska"
            year="2023"
            services="Brand development"
            extras=""
          />
          <WorkBlockImage titleMobile="Štajerska Brand development" lg={8} md={8}>
            <Img
              loading="eager"
              fluid={data.workImageStajerska.childImageSharp.fluid}
              alt="Štajerska Brand development"
            />
          </WorkBlockImage>
        </WorkBlock>
        <WorkBlock>
          <WorkBlockTextLeft
            title="Verstovšek Estate"
            year="2023"
            services="Brand development"
            extras=""
          />
          <WorkBlockImage titleMobile="Verstovšek Estate Brand development" lg={8} md={8}>
            <Img
              loading="eager"
              fluid={data.workImageVerstovsek.childImageSharp.fluid}
              alt="Verstovšek Estate Brand development"
            />
          </WorkBlockImage>
        </WorkBlock>
      <WorkBlock>
          <WorkBlockTextLeft
            title="Cultural district Mnoriti"
            year="2022"
            services="Concept, Branding"
            extras=""
          />
          <WorkBlockImage lg={4} md={4}>
            <Img fluid={data.workImageMinoriti1.childImageSharp.fluid} alt="Minoriti 1" />
          </WorkBlockImage>
          <WorkBlockTextLeft
            title="Minoriti"
            year="2022"
            services="Concept, Branding"
            extras=""
            mobileOnly="yes"
          />
          <WorkBlockImage lg={4} md={4}>
            <Img
              loading="eager"
              fluid={data.workImageMinoriti2.childImageSharp.fluid}
              alt="Minoriti 2"
              style={{ width: '100%' }}
            />
          </WorkBlockImage>
        </WorkBlock>
      <WorkBlock>
          <WorkBlockTextLeft
            title="Bevog"
            year="2022"
            services="Lulu Packaging design"
            extras=""
          />
          <WorkBlockImage titleMobile="Lulu packaging design" lg={8} md={8}>
            <Img
              loading="eager"
              fluid={data.workImageBevogLulu.childImageSharp.fluid}
              alt="Bevog Lulu Packaging design"
            />
          </WorkBlockImage>
        </WorkBlock>
        <WorkBlock>
          <WorkBlockTextLeft
            title="Parson Premium Gin"
            year="2021"
            services="Branding and packaging design"
            extras={[
              <StrongText>Awards</StrongText>,
              <AwardTitle>Graphis</AwardTitle>,
              <AwardText>Gold</AwardText>,
              <AwardTitle>World Gin Awards</AwardTitle>,
              <AwardText>Bronze</AwardText>,
            ]}
          />
          <WorkBlockImage titleMobile="Parson Premium Gin" lg={8} md={8}>
            <Img
              loading="eager"
              fluid={data.workImageParsonsGin.childImageSharp.fluid}
              alt="Parson Branding"
            />
          </WorkBlockImage>
        </WorkBlock>

        <WorkBlock>
          <WorkBlockTextLeft
            title="Malner cider"
            year="2021"
            services="Packaging design"
            extras={[
              <StrongText>Awards</StrongText>,
              <AwardTitle>Graphis</AwardTitle>,
              <AwardText>Silver</AwardText>,
            ]}
          />
          <WorkBlockImage titleMobile="Malner Cider" lg={8} md={8}>
            <Img
              loading="eager"
              fluid={data.workImageMalner.childImageSharp.fluid}
              alt="Malner Cider Branding"
            />
          </WorkBlockImage>
        </WorkBlock>
        <WorkBlock>
          <WorkBlockTextLeft title="Becycle" year="2020" services="Hotel Branding" extras="" />
          <WorkBlockImage titleMobile="Becycle" lg={8} md={8}>
            <Img
              loading="eager"
              fluid={data.workImageBecycle.childImageSharp.fluid}
              alt="Becycle Branding"
            />
          </WorkBlockImage>
        </WorkBlock>
        <WorkBlock>
          <WorkBlockTextLeft
            title="Borštnikovo"
            year="2013"
            services="Festival Branding"
            extras=""
          />
          <WorkBlockImage lg={5} md={5}>
            <Img fluid={data.workImageBorstnikovo.childImageSharp.fluid} alt="Borštnikovo Poster" />
          </WorkBlockImage>
          <WorkBlockTextLeft
            title="Hodnik"
            year="2015 - 2019"
            services="Concept, Branding, Events"
            extras={[
              <StrongText>Awards</StrongText>,
              <AwardTitle>Brumen</AwardTitle>,
              <AwardText>Recognition for excellent Slovenian design</AwardText>,
            ]}
            mobileOnly="yes"
          />
          <WorkBlockImage lg={3} md={3}>
            <Img
              loading="eager"
              fluid={data.workImageHodnik.childImageSharp.fluid}
              alt="Hodnik Poster"
              style={{ width: '100%' }}
            />
          </WorkBlockImage>
          <WorkBlockTextRight
            title="Hodnik"
            year="2015 - 2019"
            services="Concept, Branding, Events"
            extras={[
              <StrongText>Awards</StrongText>,
              <AwardTitle>Brumen</AwardTitle>,
              <AwardText>Recognition for excellent Slovenian design</AwardText>,
            ]}
          />
        </WorkBlock>

        <WorkBlock>
          <WorkBlockTextLeft title="Bevog" year="2021" services="Lola Packaging design" extras="" />
          <WorkBlockImage titleMobile="Bevog Lola" lg={8} md={8}>
            <Img
              loading="eager"
              fluid={data.workImageBevogLola.childImageSharp.fluid}
              alt="Bevog Lola Branding"
            />
          </WorkBlockImage>
        </WorkBlock>
        <WorkBlock>
          <WorkBlockTextLeft
            title="Bevog"
            year="2021"
            services="Buzz Packaging design"
            extras={[
              <StrongText>Awards</StrongText>,
              <AwardTitle>Favourite Design</AwardTitle>,
              <AwardText>Design of the day</AwardText>,
            ]}
          />
          <WorkBlockImage titleMobile="Bevog Buzz" lg={8} md={8}>
            <Img
              loading="eager"
              fluid={data.workImageBevogBuzz.childImageSharp.fluid}
              alt="Bevog Buzz Branding"
            />
          </WorkBlockImage>
        </WorkBlock>
        <WorkBlock>
          <WorkBlockTextLeft title="Bevog" year="2020" services="Nitro Packaging" extras="" />
          <WorkBlockImage lg={8} md={8}>
            <Img
              loading="eager"
              fluid={data.workImageBevogNoBgBg.childImageSharp.fluid}
              alt="Bevog Nitro Packaging"
              style={{ width: '100%', position: 'relative' }}
            />
          </WorkBlockImage>
        </WorkBlock>
      </Work>
      <Container>
        <Wrapper>
          <Row>
            <Column sm={12}>
              <SimpleFooter />
            </Column>
          </Row>
        </Wrapper>
      </Container>
      {/* fullscreen Video */}
      <div
        className={
          !fullscreen
            ? 'opacity-0 invisible transition-opacity fixed'
            : 'opacity-100 visible z-10 w-full h-screen top-0 left-0 transition-opacity fixed'
        }
      >
        <VideoPlayer options={options} onReady={handlePlayerReady} />
        <button
          className={`${
            !controls ? 'opacity-0 delay-500' : 'opacity-100'
          } transition-opacity duration-700  absolute right-2 top-2 w-10 h-10 border text-white`}
          onClick={handleStop}
        >
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.5893 8.45868L15.932 14.1147L10.276 8.45868L8.39062 10.344L14.0466 16L8.39062 21.656L10.276 23.5413L15.932 17.8853L21.5893 23.5413L23.4746 21.656L17.8186 16L23.4746 10.344L21.5893 8.45868Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
      {/* / fullscreen Video */}
    </>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "contact", "index", "terms", "work"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    workImageHodnik: file(relativePath: { eq: "images/work_hodnik1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 430, maxHeight: 602, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageBecycle: file(relativePath: { eq: "images/work_becycle.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageBevog: file(relativePath: { eq: "images/work_bevog_beers.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageJoan: file(relativePath: { eq: "images/work_joan.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
          workImageSpacekart: file(relativePath: { eq: "images/work_Spacekart.png" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageBorstnikovo: file(relativePath: { eq: "images/work_borstnikovo.png" }) {
      childImageSharp {
        fluid(maxWidth: 430, maxHeight: 602, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageBevogNoBg: file(relativePath: { eq: "images/work_bevog_beers_nobg.png" }) {
      childImageSharp {
        fluid(maxWidth: 860, maxHeight: 484, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageBevogNoBgBg: file(relativePath: { eq: "images/work_bevog_beers_nobg_bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 860, maxHeight: 484, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageArtRebel: file(relativePath: { eq: "images/work_ArtRebel.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageBevogBuzz: file(relativePath: { eq: "images/work_Bevog_Buzz.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageBevogLola: file(relativePath: { eq: "images/work_Bevog_Lola.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 100) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageBevogLola2: file(relativePath: { eq: "images/work_Bevog_Lola_2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 100) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageBigBangImmersive3D: file(relativePath: { eq: "images/work_BigBang_Immersive3D.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageBigBangMega: file(relativePath: { eq: "images/work_BigBang_Mega.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageBigBangSC22: file(relativePath: { eq: "images/work_BigBang_SC22_2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageBigBangUAUNSZ: file(relativePath: { eq: "images/work_BigBang_UAU_NSZ.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageBigBangUAUSP22: file(relativePath: { eq: "images/work_BigBang_UAU_SP22.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageBigBangUAU: file(relativePath: { eq: "images/work_BigBang_UAU.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 800, maxHeight: 600, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageFab: file(relativePath: { eq: "images/work_Fab.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageMinoriti1: file(relativePath: { eq: "images/work_minoriti_1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 602, maxHeight: 602, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageMinoriti2: file(relativePath: { eq: "images/work_minoriti_2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 602, maxHeight: 602, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageMinoriti3: file(relativePath: { eq: "images/work_minoriti_3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageFab2: file(relativePath: { eq: "images/work_Fab_2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageGAAC: file(relativePath: { eq: "images/work_Google_Arts_And_Culture_clip.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageHRC: file(relativePath: { eq: "images/work_HRC.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 100) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageMalner: file(relativePath: { eq: "images/work_Malner.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageMalner2: file(relativePath: { eq: "images/work_Malner_2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, maxHeight: 600, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageMOL: file(relativePath: { eq: "images/work_MOL_1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 539, maxHeight: 800, quality: 100) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageParsonsGin: file(relativePath: { eq: "images/work_ParsonsGIn.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 0) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageSamsung: file(relativePath: { eq: "images/work_SAMSUNG.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageSamsung2: file(relativePath: { eq: "images/work_Samsung_2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, maxHeight: 600, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageSejem: file(relativePath: { eq: "images/work_Sejem_.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 560, maxHeight: 800, quality: 100) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageZDL: file(relativePath: { eq: "images/work_ZDL.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageZlataLisica: file(relativePath: { eq: "images/work_ZlataLisica_Ilustracija.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageZMTDubai: file(relativePath: { eq: "images/work_ZMT_Dubai.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageBright: file(relativePath: { eq: "images/work_bright.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageStereosun: file(relativePath: { eq: "images/work_stereosun.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageNLBLisica: file(relativePath: { eq: "images/work_ZlataLisica_Ilustracija.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageZMTDubai2: file(relativePath: { eq: "images/work_ZMT_Dubai_2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageStajerska: file(relativePath: { eq: "images/work_Stajerska.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageVerstovsek: file(relativePath: { eq: "images/work_Verstovsek.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageBevogLulu: file(relativePath: { eq: "images/work_bevog_lulu.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
        workImageBorstnikovo2024: file(relativePath: { eq: "images/work_Borstnikovo_2024.png" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
  }
`;
