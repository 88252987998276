import React from 'react';
import styled from 'styled-components';

import { responsiveFont } from 'styles/utils';
import { variables, breakpoints } from 'styles/variables';
import { Column } from 'components/grid/Column';

interface WorkBlockTextRightProps {
  title: string;
  services: string;
  extras: string;
  mobileOnly: string;
}

const WorkTitle = styled.div`
  ${responsiveFont(18, 24)};
  font-family: ${variables.font.familyHeading};
  font-weight: 400;
`;

const WorkDetailsRight = styled.div`
  display: none;
  text-align: left;
  padding-top: 50px;
  padding-bottom: 10px;
  @media (min-width: ${breakpoints.md}) {
    padding-top: 0px;
    display: block;
    padding-bottom: 0px;
  }
`;

const WorkBlockExtras = styled.p`
  padding-top: 20px;
  ${responsiveFont(10, 12)};
`;

export const WorkBlockTextRight = ({ title, services, extras }: WorkBlockTextRightProps) => (
  <>
    <Column
      md={2}
      lg={2}
      sm={12}
      data-sal="slide-up"
      data-sal-delay="0"
      data-sal-duration="700"
      data-sal-easing="ease"
    >
      <WorkDetailsRight>
        <WorkTitle>{title}</WorkTitle>
        <p>{services}</p>
        <WorkBlockExtras>{extras}</WorkBlockExtras>
      </WorkDetailsRight>
    </Column>
  </>
);
